<template>
    <LazyAtomModal class="plp-item__modal" :open="!!product" :show-x-button="false" @update:open="closeNote">
        <Note v-if="product" :product="product" @close="closeNote" />
    </LazyAtomModal>
</template>

<script lang="ts" setup>
import Note from '~/components/Molecule/Plp/Note.vue'
import type { CatalogProduct } from '~/composables/types/api/searchDiscover/catalogSearch'

const { $emitter } = useNuxtApp()

const product = ref<CatalogProduct | null>(null)

const closeNote = () => {
    product.value = null
    $emitter.$emit('noteModal.closed')
}

const openNote = (noteProps: any) => {
    product.value = noteProps.product
    $emitter.$emit('noteModal.opened')
}

onMounted(() => {
    $emitter.$on('openNote', openNote)
})

onBeforeUnmount(() => {
    $emitter.$off('openNote', openNote)
})
</script>
